import { useContext } from 'react'

import AppContext from '@storage/context/AppContext'

export const useAppContext = () => {
    const {
        appState,
        setError,
        removeError,
        setAdobeAnalytics,
        setConfiguration,
        setStopLoadingOnError,
        setLoading,
        setShowLoading,
        setViewportSize,
        setRouteSnapshot,
        setHideInactivityMessage,
    } = useContext(AppContext)

    return {
        appState,
        setError,
        removeError,
        setAdobeAnalytics,
        setConfiguration,
        setStopLoadingOnError,
        setLoading,
        setShowLoading,
        setViewportSize,
        setRouteSnapshot,
        setHideInactivityMessage,
    }
}
