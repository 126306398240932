import React, { useReducer, ReactNode, useMemo } from 'react'

import { DeviceName } from '@ds.e/foundation'

import {
    AdobeAnalytics,
    Configuration,
    Error,
    RouteSnapshot,
} from '../interfaces/types'
import INITIAL_APP_STATE from '../state/appState'
import AppContext from './AppContext'

import { appStateReduces } from './AppContextReducer'

interface AppContextProviderProps {
    children: ReactNode // Define the children prop type as ReactNode
}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
    children,
}) => {
    const [appState, dispatch] = useReducer(appStateReduces, INITIAL_APP_STATE)

    const setAdobeAnalytics = (adobeAnalytics: AdobeAnalytics) => {
        dispatch({ type: 'setAdobeAnalytics', payload: adobeAnalytics })
    }

    const setConfiguration = (configuration: Configuration) => {
        dispatch({ type: 'setConfiguration', payload: configuration })
    }

    const setError = (error: Error) => {
        dispatch({ type: 'setError', payload: error })
    }

    const removeError = () => {
        dispatch({ type: 'removeError' })
    }

    const setStopLoadingOnError = (stopLoading: boolean) => {
        dispatch({ type: 'stopLoadingOnError', payload: stopLoading })
    }

    const setLoading = (loading: any) => {
        dispatch({ type: 'setLoading', payload: loading })
    }

    const setShowLoading = (showLoading: boolean) => {
        dispatch({ type: 'setShowLoading', payload: showLoading })
    }

    const setViewportSize = (viewportSize: DeviceName) => {
        dispatch({ type: 'setViewportSize', payload: viewportSize })
    }

    const setHideInactivityMessage = (hideInactivityMessage: boolean) => {
        dispatch({
            type: 'setHideInactivityMessage',
            payload: hideInactivityMessage,
        })
    }

    const setRouteSnapshot = (routeSnapshot: RouteSnapshot) => {
        dispatch({ type: 'setRouteSnapshot', payload: routeSnapshot })
    }

    const contextValue = {
        appState,
        setError,
        removeError,
        setAdobeAnalytics,
        setConfiguration,
        setStopLoadingOnError,
        setLoading,
        setShowLoading,
        setViewportSize,
        setRouteSnapshot,
        setHideInactivityMessage,
    }

    // Wrap the context value object in a useMemo hook
    const memoizedContextValue = useMemo(
        () => contextValue,
        [
            appState,
            setError,
            removeError,
            setAdobeAnalytics,
            setConfiguration,
            setStopLoadingOnError,
            setLoading,
            setShowLoading,
            setViewportSize,
            setRouteSnapshot,
            setHideInactivityMessage,
        ],
    )
    return (
        <AppContext.Provider value={memoizedContextValue}>
            {children}
        </AppContext.Provider>
    )
}
